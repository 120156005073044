import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import * as S from "./HeroMobile.style";
import { useRouter } from "next/router";
import { pushEvent } from "@tm/utility/analytics";
import MapCompNew from "../MapCompNewMobile/MapCompNew.mobile";

type Renderers = {
  [type: string]: (props: any) => React.ReactNode;
};

const HeroMobile = ({
  title,
  subTitle,
  mapImg,
  foreignCurrencyCTALinkEvent,
  forexCardsCTALinkEvent,
  travelLoanCTALinkEvent,
  travelInsuranceCTALinkEvent,
  mapData,
}: any) => {
  const router = useRouter();

  const handleLinkClick = (props: any) => {
    if (props?.title === "forex-currency") {
      pushEvent(foreignCurrencyCTALinkEvent);
    } else if (props?.title === "forex-card") {
      pushEvent(forexCardsCTALinkEvent);
    } else if (props?.title === "travel-insurance") {
      pushEvent(travelInsuranceCTALinkEvent);
    } else if (props?.title === "travel-credit-line") {
      pushEvent(travelLoanCTALinkEvent);
    }
    router.push(props?.href);
  };

  const renderers: Renderers = {
    a: (props: any) => (
      <a onClick={() => handleLinkClick(props)}>{props.children}</a>
    ),
  };

  return (
    <S.LandingMap>
      <S.LandingMapWrap>
        <S.LandingMapLeft>
          {title && <S.LandingMapHd>{title}</S.LandingMapHd>}
          {subTitle && (
            <ReactMarkdown components={renderers}>{subTitle}</ReactMarkdown>
          )}
        </S.LandingMapLeft>
        <S.LandingMapRight>
          {mapData && <MapCompNew mapData={mapData} />}
        </S.LandingMapRight>
      </S.LandingMapWrap>
    </S.LandingMap>
  );
};

export default HeroMobile;
