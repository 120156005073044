import { BLUE_COLOR, TEXT_COLOR } from "@tm/utility/theme";
import styled from "styled-components";

export const LandingMapParent = styled.div`
  background: #000;
`;

export const LandingMap = styled.div`
  background: #010101;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding-bottom: 3rem;
`;

export const LandingMapWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  background-size: 466px 434px;
  transform: translateY(53px);
  opacity: 1;
  background-position: -79px 262px;
  padding: 16px;
`;

export const LandingMapScroll = styled.div<any>`
  width: 34px;
  height: 60px;
  background: #010101;
  border: 1px solid #717171;
  border-radius: 33px;
  padding: 10px;
  position: relative;
  top: -40px;
  opacity: 0;

  ${(props) =>
    props?.scroll &&
    `
 opacity:1;
	`}
`;

export const ScrollBall = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: #fff;
  animation: bounce 1.5s;
  animation-iteration-count: infinite;

  @keyframes bounce {
    from {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    to {
      transform: translate3d(0, 26px, 0);
      opacity: 0;
    }
  }
`;

export const LandingMapLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;

  img {
    margin-right: 0.75rem;
    vertical-align: middle;
  }

  img[alt="Tata Logo"] {
    margin-right: 0;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 1rem;
  }

  p em {
    color: ${BLUE_COLOR.lightSkyBlue};
    font-weight: 700;
    text-decoration: none;
    font-style: normal;
  }

  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: ${TEXT_COLOR.aquamarine};
    // margin-bottom: 16px;
    margin-top: 1rem;
  }

  h4 {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }

  @media only screen and (max-width: 400px) {
    img {
      margin-right: 0.25rem;
      vertical-align: middle;
    }
  }
`;

export const LandingMapRight = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LandingMapIndia = styled.div`
  // width: 326px;
  // height: 357px;
  flex-shrink: 0;
`;

export const LandingMapBrand = styled.div`
  margin-bottom: 22px;
  height: 25px;
  align-items: center;
  display: flex;
  gap: 12px;
`;

export const LandingMapHd = styled.h2`
  background: linear-gradient(
    91.72deg,
    #ffe2e8 0.38%,
    #fffcfc 38.24%,
    #8cc8ea 75.95%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 16px;
  font-family: "Raleway";
`;

export const LandingMapInfo = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
  color: rgba(255, 255, 255, 0.8);

  & a {
    color: #6abcff;
    font-weight: 700;
  }
`;

export const LandingMapCount = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #85d8ba;
`;
