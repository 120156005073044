import React from "react";
import {
  RippleCompStyle,
  InnerDot,
  RippleCompWrap,
} from "./MapCompNewMobile.style";

interface RippleProps {
  posX?: string;
  posY?: string;
  colorCode?: string;
  bgColor?: string;
  borderColor?: string;
  pointDetailType?: boolean;
  icon?: any;
  animDelay?: number;
}

const RippleComp: React.FC<RippleProps> = ({
  posX,
  posY,
  pointDetailType,
  bgColor,
  borderColor,
  icon,
  animDelay,
}) => {
  return (
    <RippleCompWrap key={+new Date()} posX={posX} posY={posY}>
      <RippleCompStyle
        pointDetailType={pointDetailType}
        bgColor={bgColor}
        borderColor={borderColor}
        animDelay={animDelay}
      ></RippleCompStyle>
      <InnerDot animDelay={animDelay} borderColor={borderColor}>
        {icon}
      </InnerDot>
    </RippleCompWrap>
  );
};

export default RippleComp;
