import styled, { keyframes } from "styled-components";

const ripple = keyframes`
  0% {
    width: 0;
    height: 0;
    border-width:0;
    opacity: 1;
  }
  100% {
   width:202px;
   height:202px;
   opacity: 0;
   border-width:1px;
  }
`;

const ripple2 = keyframes`
  0% {
    width: 0;
    height: 0;
    border-width:0;
    opacity: 1;
  }
  100% {
   width:47px;
   height:47px;
   opacity:0;
   border-width:1px;
  }
`;

const rippleIcon = keyframes`
  0% {
    opacity: 0;
  }
  2% {
    opacity: 1;
  }
  100% {
   opacity:0;
  }
`;

export const IndiaWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #010101;
  img[alt="map"] {
    margin-top: -40px;
  }
`;

export const RippleCompWrap = styled.div<any>`
  position: absolute;
  top: ${(p) => (p.posY ? p.posY : "auto")};
  left: ${(p) => (p.posX ? p.posX : "auto")};
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(p) => (p.pointDetailType ? "202px" : "47px")};
  height: ${(p) => (p.pointDetailType ? "202px" : "47px")};
`;

export const RippleCompStyle = styled.div<any>`
  width: 0;
  height: 0;
  opacity: 0;
  background: ${(p) => (p.bgColor ? p.bgColor : "")};
  border: 1px solid ${(p) => (p.borderColor ? p.borderColor : "")};
  border-radius: 100%;
  animation: ${(p) => (p.pointDetailType ? ripple : ripple2)} ease-in-out;
  animation-duration: ${(p) => (p.pointDetailType ? 5 : 4)}s;
  animation-delay: ${(p) => (p.animDelay ? p.animDelay : 0)}s;
  flex-shrink: 0;
`;

export const InnerDot = styled.div<any>`
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: ${(p) => (p.borderColor ? p.borderColor : "")};
  position: absolute;
  animation: ${rippleIcon} ease-in-out;
  animation-duration: ${(p) => (p.pointDetailType ? 5 : 4)}s;
  animation-delay: ${(p) => (p.animDelay ? p.animDelay + 0.7 : 0)}s;
  opacity: 0;
  svg {
    position: absolute;
    top: -23px;
    left: -10px;
  }
`;

export const PointItems = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  flex: 1;
  border-right: 1px solid rgba(255, 255, 255, 0.16);
  text-align: center;

  &:last-child {
    border-right: none;
  }
`;
