import React, { useState, useEffect } from "react";
import { theme } from "@tm/utility/global";

import FlexComp from "../FlexComp/FlexComp";
import H6Tag, { H6Color } from "../TextStyles/H6Tag";
import SpanTag from "../TextStyles/SpanTag";
import { IndiaWrap, PointItems } from "./MapCompNewMobile.style";
import RippleComp from "./RippleComp.mobile";
import { cities } from "@tm/utility/constants";
import { IImage } from "@tm/interfaces";
import { useMapData } from "../MapComp/hooks/useMapData";
import useInfiniteMapEvents from "../../hooks/useInfiniteMapEvents";
import { Image } from "../Image";

interface MapProps {
  mapPoints?: object[];
  mapData?: any;
}

function randomIntFromInterval(min: number, max: number) {
  var delay;
  delay = Math.floor(Math.random() * (max - min + 1) + min);
  return delay;
}

const MapCompNew: React.FC<MapProps> = ({ mapData }) => {
  const [array] = useMapData();
  const pointType = mapData?.pointType;

  const { displayedArray } = useInfiniteMapEvents(array);

  const [screenWidth, setScreenWidth] = useState(0);
  const number = 1500 / 695;

  var midX = screenWidth / 2;
  var midY = 200;

  useEffect(() => {
    setScreenWidth(window.innerWidth - 32);
  }, []);

  return (
    <IndiaWrap className="indiaMapWrap">
      <FlexComp margin="0 0 35px">
        <img {...mapData?.mapImg} alt="map" />
        {displayedArray &&
          displayedArray.map((item: any, index: number) => {
            const cityName = item.cityName;
            var x;
            var x1;
            var newx;
            var x2;
            var y;
            var y1;
            var newy;
            var y2;
            x2 =
              ((cities[cityName.toLowerCase()]?.[0] / number - 204) *
                screenWidth) /
              360;
            y2 =
              ((cities[cityName.toLowerCase()]?.[1] / number - 160) *
                screenWidth) /
              360;
            x = `${
              ((cities[cityName.toLowerCase()]?.[0] / number - 204) *
                screenWidth) /
              360
            }px`;
            y = `${
              ((cities[cityName.toLowerCase()]?.[1] / number - 160) *
                screenWidth) /
              360
            }px`;
            newx = `${(x2 + midX) / 2}px`;
            newy = `${(y2 + midY) / 2}px`;
            x1 =
              JSON.stringify(item.event_name) == JSON.stringify(pointType[0])
                ? newx
                : x;
            y1 =
              JSON.stringify(item.event_name) == JSON.stringify(pointType[0])
                ? newy
                : y;
            return (
              <RippleComp
                key={index}
                posX={x1}
                posY={y1}
                bgColor={item.event_name.bgColorProp}
                borderColor={item.event_name.borderColorProp}
                icon={item.event_name.icon}
                pointDetailType={
                  JSON.stringify(item.event_name) ==
                  JSON.stringify(pointType[0])
                }
                animDelay={randomIntFromInterval(1, 10)}
              />
            );
          })}
      </FlexComp>
      <H6Tag color={theme.whiteText as H6Color} margin="0 0 12px">
        {mapData?.mapTitle}
      </H6Tag>

      <FlexComp gap="0px">
        {pointType &&
          pointType.map((item: any) => (
            <PointItems flexDirection="column" gap="3px" key={item.type}>
              <Image src={item.icon} height="24" width="24" loading="eager" />
              <SpanTag color={theme.greyText8} fontSize="xs">
                {item.type}
              </SpanTag>
            </PointItems>
          ))}
      </FlexComp>
    </IndiaWrap>
  );
};

export default MapCompNew;
